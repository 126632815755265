import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthService from "services/AuthService";
import CurrentUser from "services/CurrentUser";
import { Menu, Navbar, Footer } from "components/layout";

export const Dashboard = ({ contentClass, children }) => {
    // if (!AuthService.isToken()) {
    //     return <Navigate to="/login" replace={true} />;
    // }

    return (
        <div className={"h-full w-full dashboard"}>
            <Menu />

            <div className={"dashboard-container"}>
                <Navbar />

                <div className={"content " + contentClass}>{children}</div>

                <Footer />
            </div>
        </div>
    );
};
