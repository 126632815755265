import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    IoAddOutline,
    IoChevronDownOutline,
    IoChevronUpOutline,
    IoCreateOutline,
    IoEyeOutline,
    IoHappyOutline,
    IoTrashOutline,
} from "react-icons/io5";
import { Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import CurrentUser from "services/CurrentUser";
import { Badge, Button, ContentHeading } from "components/ui";
import KidsModalView from "./KidsModalView";
import { GroupIcon } from "./GroupIcon";

function KidsList({ kids, handleLoadData }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ show: false, data: null });
    const [filterModel, setFilterModel] = useState({ items: [] });

    const columns = [
        { field: "name", headerName: t("Full name", { ns: "common" }), flex: 1 },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 180,
            valueGetter: (params) => `${params.row.group.name || ""}`,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4">
                        <GroupIcon icon={params.row.group.icon} color={params.row.group.color} size={"1.25em"} />
                        {params.row.group.name || ""}
                    </div>
                );
            },
        },
        {
            field: "joinStatus",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            valueGetter: (params) => `${params.row.joinStatus || ""}`,
            renderCell: (params) => {
                let color = "";
                switch (params.row.joinStatus) {
                    case "awaiting_approval":
                        color = "#dc2626";
                        break;
                    case "accepted":
                        color = "#166534";
                        break;
                    case "rejected":
                        color = "";
                        break;
                    case "removed":
                        color = "";
                        break;
                    default:
                        break;
                }
                return <Badge color={color}>{t(params.row.joinStatus, { ns: "common" })}</Badge>;
            },
        },
        {
            field: "birthday",
            headerName: t("Birthday", { ns: "common" }),
            width: 180,
        },
        {
            field: "diet",
            headerName: t("Diet", { ns: "common" }),
            width: 180,
            valueGetter: (params) =>
                `${params.row.joinStatus === "accepted" && params.row.allergy ? params.row.diet.name : "---"}`,
        },
        {
            field: "allergy",
            headerName: t("Allergy", { ns: "common" }),
            flex: 1,
            renderCell: (params) => {
                return params.row.joinStatus === "accepted" && params.row.allergy
                    ? params.row.allergy.map((allergy) => allergy.name)
                    : "---";
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return params.row.joinStatus === "accepted" ? (
                    <>
                        <Link to={"/institution/kids/" + params.id + "/edit"} className={"btn-action"}>
                            <IoCreateOutline size={"1.25rem"} />
                        </Link>
                        <Button className={"btn-action"} onClick={() => openModal(params.id)}>
                            <IoEyeOutline size={"1.25rem"} />
                        </Button>
                        <Link to={"/institutions/" + params.id + "/edit"} className={"btn-action"}>
                            <IoTrashOutline size={"1.25rem"} />
                        </Link>
                    </>
                ) : params.row.joinStatus === "awaiting_approval" ? (
                    <>
                        <Button className={"btn-action"} onClick={() => openModal(params.id)}>
                            <IoEyeOutline size={"1.25rem"} />
                        </Button>
                    </>
                ) : (
                    ""
                );
            },
        },
    ];

    useEffect(() => {
        setFilterModel({
            items: [
                {
                    field: "group",
                    operator: "contains",
                    value: searchParams.get("group"),
                },
            ],
        });
    }, [searchParams]);

    const openModal = (kidId) => {
        setModal({ show: true, data: { kidId: kidId } });
    };

    const handleModalClose = () => {
        handleLoadData();
        setModal({ show: false, data: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/institution/kids/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add kid", { ns: "button" })}
            >
                <IoHappyOutline size={"1.5rem"} />
                {t("List of kids", { ns: "common" })}
            </ContentHeading>

            <div className="table-box" style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={kids}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    filterModel={filterModel}
                    pageSizeOptions={[10, 25, 50]}
                    disableColumnMenu={true}
                    width={"100%"}
                    slots={{
                        // toolbar: GridToolbar,
                        columnSortedDescendingIcon: IoChevronUpOutline,
                        columnSortedAscendingIcon: IoChevronDownOutline,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </div>

            {modal.show && modal.data && <KidsModalView data={modal.data} closeModal={handleModalClose} />}
        </>
    );
}

export default KidsList;
