import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { useSessionStorage } from "hooks/useSessionStorage";
import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Box, ContentHeading } from "components/ui";

function InstitutionForm({ institution }) {
    const { t } = useTranslation();
    const [user, setUser] = useSessionStorage("user");

    const [typesOptions, setTypesOptions] = useState([]);
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);

    const formSchema = [
        {
            name: "heading1",
            componentType: "heading",
            label: t("Institution information", { ns: "common" }),
            fieldClassName: "col-span-full",
        },
        {
            name: "name",
            label: t("Name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-4",
        },
        {
            name: "institutionType",
            label: t("Institution type", { ns: "common" }),
            componentType: "select",
            required: true,
            fieldClassName: "col-span-2",
            options: typesOptions,
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-4",
        },
        {
            name: "number",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "premises",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "zipCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "openingDays",
            label: t("Opening days", { ns: "common" }),
            componentType: "select",
            required: true,
            isMulti: true,
            fieldClassName: "col-span-4",
            options: weekDaysOptions,
        },
        {
            name: "openTime",
            label: t("Opening hours", { ns: "common" }),
            componentType: "time",
            required: true,
            fieldClassName: "col-span-1",
        },
        {
            name: "closeTime",
            label: t("Closing time", { ns: "common" }),
            componentType: "time",
            required: true,
            fieldClassName: "col-span-1",
        },
        {
            name: "directorName",
            label: t("Director", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "heading2",
            componentType: "heading",
            label: t("Additional information", { ns: "common" }),
            fieldClassName: "col-span-full",
        },
        {
            name: "entryDescription",
            label: t("Entrance to the institution", { ns: "common" }),
            componentType: "text",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "otherDescription",
            label: t("Transferring children to teachers/coaches", { ns: "common" }),
            componentType: "text",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "institutionDescription",
            label: t("Institution description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];

    useEffect(() => {
        AxiosInstance({
            url: "institution-type",
            method: "GET",
        }).then((response) => {
            setTypesOptions(response.data);
        });

        AxiosInstance({
            url: "week-days",
            method: "GET",
        }).then((response) => {
            setWeekDaysOptions(response.data);
        });
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "institutions";
        let method = "POST";

        if (institution) {
            url += "/" + institution.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    if (!institution) {
                        resetForm({ values: "" });

                        setUser({ ...user, institution: response.data.data });

                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    } else {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {institution ? t("Edit institution", { ns: "common" }) : t("Add institution", { ns: "common" })}
            </ContentHeading>

            <Box>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={institution}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={"/institution/my"}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default InstitutionForm;
