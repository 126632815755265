import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Alerts, Box, ContentHeading } from "components/ui";

function TeacherForm({ teacher }) {
    const { t } = useTranslation();

    const [alerts, setAlerts] = useState(null);

    const formSchema = [
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-4",
        },
        {
            name: "number",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "premises",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "zipCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "description",
            label: t("Description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "qualifications",
            label: t("Qualifications", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setAlerts(null);
        setSubmitting(true);

        let url = "teachers";
        let method = "POST";

        if (teacher) {
            url += "/" + teacher.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                setAlerts(response.data);

                if (!teacher && response.status === 201) {
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                if (error.response && Object.keys(error.response.data).length > 0) {
                    setAlerts(error.response.data);
                } else if (error.request) {
                    setAlerts({ status: error.request.status, message: error.message });
                } else {
                    setAlerts(error.message);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {teacher ? t("Edit teacher", { ns: "common" }) : t("Add teacher", { ns: "common" })}
            </ContentHeading>

            <Box>
                {alerts && <Alerts alerts={alerts} />}

                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={teacher}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={"/institution/teachers"}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default TeacherForm;
