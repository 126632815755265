import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    IoAccessibilityOutline,
    IoAlbumsOutline,
    IoBusinessOutline,
    IoCalendarOutline,
    IoChatboxEllipsesOutline,
    IoDocumentTextOutline,
    IoHappyOutline,
    IoInformationCircleOutline,
    IoLogOutOutline,
    IoPeopleOutline,
    IoRibbonOutline,
    IoSchoolOutline,
} from "react-icons/io5";

import AuthService from "services/AuthService";
import CurrentUser from "services/CurrentUser";

export const Menu = () => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const logOut = () => {
        AuthService.logout();
        window.location.reload();
    };

    return (
        <div className="menu">
            {currentUser && (
                <ul className="menu-nav mr-auto">
                    {/* <li className="nav-item">
                        <Link to={"/calendar"} className="nav-link">
                            <IoCalendarOutline size={"1.25em"} />
                            {t("Calendar", { ns: "menu" })}
                        </Link>
                    </li> */}
                    {currentUser.accountType === "brand" && (
                        <>
                            <li className="nav-item">
                                <NavLink
                                    to="/brand/brands"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoRibbonOutline size={"1.25em"} />
                                    {t("My offer", { ns: "menu" })}
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/activities"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoAccessibilityOutline size={"1.25em"} />
                                    {t("Activities", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/billings"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoDocumentTextOutline size={"1.25em"} />
                                    {t("Billings", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/materials"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoAlbumsOutline size={"1.25em"} />
                                    {t("Materials", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink
                                    to="/brand/trainings"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoSchoolOutline size={"1.25em"} />
                                    {t("Trainings", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                        </>
                    )}

                    {currentUser.accountType === "institution" && (
                        <>
                            <li className="nav-item">
                                <NavLink
                                    to="/institution/my"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoBusinessOutline size={"1.25em"} />
                                    {t("My institution", { ns: "menu" })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/institution/kids"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoHappyOutline size={"1.25em"} />
                                    {t("Kids", { ns: "menu" })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/institution/teachers"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoPeopleOutline size={"1.25em"} />
                                    {t("Teachers", { ns: "menu" })}
                                </NavLink>
                            </li>
                        </>
                    )}

                    {currentUser.accountType === "parent" && (
                        <>
                            <li className="nav-item">
                                <NavLink
                                    to="/parent/institutions"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoBusinessOutline size={"1.25em"} />
                                    {t("Kid institution", { ns: "menu" })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/parent/kids"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoHappyOutline size={"1.25em"} />
                                    {t("My kid", { ns: "menu" })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/parent/activities"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoAccessibilityOutline size={"1.25em"} />
                                    {t("Kid activities", { ns: "menu" })}
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink
                                    to="/parent/billings"
                                    className={({ isActive, isPending }) =>
                                        "nav-link " + (isPending ? "pending" : isActive ? "active" : "")
                                    }
                                >
                                    <IoDocumentTextOutline size={"1.25em"} />
                                    {t("Billings", { ns: "menu" })}
                                </NavLink>
                            </li> */}
                        </>
                    )}

                    {/* <li className="nav-item">
                        <Link to={"/notifications"} className="nav-link">
                            <IoChatboxEllipsesOutline size={"1.25em"} />
                            {t("Notifications", { ns: "menu" })}
                        </Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link to={"/about-app"} className="nav-link">
                            <IoInformationCircleOutline size={"1.25em"} />
                            {t("About The Kidly", { ns: "menu" })}
                        </Link>
                    </li> */}
                    <li className="nav-item">
                        <button onClick={() => logOut()} className="nav-link">
                            <IoLogOutOutline size={"1.25em"} />
                            {t("Logout", { ns: "menu" })}
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};
