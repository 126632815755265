import React, { useEffect, useState } from "react";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import InstitutionView from "./components/InstitutionView";

export const InstitutionPage = () => {
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [institution, setInstitution] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id,
            method: "GET",
        })
            .then((response) => {
                setInstitution(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"institutions"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : institution ? (
                <InstitutionView institution={institution} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
