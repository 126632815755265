import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";
import { Switch } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";

function TrainerModalWorkSchedule({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [trainer, setTrainer] = useState(null);
    const [schedule, setSchedule] = useState(null);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        setLoading(true);

        Promise.all([
            AxiosInstance({
                url: "brands/" + data.brandId + "/trainers/" + data.trainerId,
                method: "GET",
            }).then((response) => {
                setTrainer(response.data);
            }),

            AxiosInstance({
                url: "brands/" + data.brandId + "/trainers/" + data.trainerId + "/work-schedules",
                method: "GET",
            }).then((response) => {
                setSchedule(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    const handleActiveChange = (id) => {
        setSchedule(
            schedule.map((item) => {
                if (item.id === id) {
                    return { ...item, active: !item.active };
                } else {
                    return item;
                }
            })
        );
    };

    const handleAddShift = (id) => {
        setSchedule(
            schedule.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        shifts: [...item.shifts, { timeFrom: "2023-11-03T08:00", timeTo: "2023-11-03T16:00" }],
                    };
                } else {
                    return item;
                }
            })
        );
    };

    const handleRemoveShift = (id, idx) => {
        setSchedule(
            schedule.map((item) => {
                if (item.id === id) {
                    let newShifts = [...item.shifts];
                    newShifts.splice(idx, 1);

                    return {
                        ...item,
                        shifts: newShifts,
                    };
                } else {
                    return item;
                }
            })
        );
    };

    const handleShiftChange = (id, idx, type) => (e) => {
        setSchedule(
            schedule.map((item) => {
                if (item.id === id) {
                    let newShifts = [...item.shifts];
                    newShifts[idx][type] = dayjs.utc(e);

                    return {
                        ...item,
                        shifts: newShifts,
                    };
                } else {
                    return item;
                }
            })
        );
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "brands/" + data.brandId + "/trainers/" + data.trainerId + "/work-schedules",
            method: "PUT",
            data: schedule,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal
            handleCloseModal={closeModal}
            title={t("Edit trainer availability schedule", { ns: "common" })}
            big={true}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : schedule ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <strong>{t("Trainer", { ns: "common" })}:</strong>
                            {trainer.fullName}
                        </div>
                        <div className="flex gap-2">
                            <div className="border-t w-full"></div>
                        </div>

                        {schedule.map((s) => (
                            <div className="grid grid-cols-12 items-center gap-3" key={s.id}>
                                <div className="col-span-1">
                                    <Switch value={1} checked={s.active} onChange={() => handleActiveChange(s.id)} />
                                </div>
                                <div className="col-span-2 font-semibold">
                                    {t(s.dayOfWeek.name, { ns: "lexicons" })}
                                </div>
                                <div className="col-span-8">
                                    <div className="flex items-center gap-2">
                                        {s.shifts.map((shift, index) => (
                                            <>
                                                {index > 0 ? <span className="font-semibold">;</span> : ""}
                                                <div className="flex gap-2 relative" key={index}>
                                                    <MobileTimePicker
                                                        value={dayjs.utc(shift.timeFrom)}
                                                        className="form-datepicker w-16"
                                                        onChange={handleShiftChange(s.id, index, "timeFrom")}
                                                    />
                                                    <MobileTimePicker
                                                        value={dayjs.utc(shift.timeTo)}
                                                        className="form-datepicker w-16"
                                                        onChange={handleShiftChange(s.id, index, "timeTo")}
                                                    />
                                                    <Button
                                                        className={
                                                            "btn-action absolute left-2/4 -translate-x-2/4 z-10 text-red-700"
                                                        }
                                                        onClick={() => handleRemoveShift(s.id, index)}
                                                    >
                                                        <IoTrashOutline size={"1rem"} />
                                                    </Button>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <Button
                                        className={"btn-action"}
                                        disabled={!s.active || s.shifts.length === 3}
                                        onClick={() => handleAddShift(s.id)}
                                        title="Dodaj przedział"
                                    >
                                        <IoAddOutline size={"1.25rem"} />
                                    </Button>
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-center gap-2">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}
                            >
                                {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                            </button>
                            <button type="button" className="btn btn-gray" disabled={isSubmitting} onClick={closeModal}>
                                {t("Cancel", { ns: "button" })}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default TrainerModalWorkSchedule;
