export default function AuthHeader() {
    const uid = sessionStorage.getItem("uid");

    let headers = {
        "Content-Type": "application/json",
    };

    if (uid) {
        headers.Authorization = "Bearer " + uid;
    }

    return headers;
}
