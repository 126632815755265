import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import ActivityForm from "./components/ActivityForm";

export const ActivityAddPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        AxiosInstance({
            url: "brands/" + params.brandId,
            method: "GET",
        })
            .then((response) => {
                setPermissions(true);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [permissions]);

    return (
        <Dashboard contentClass={"activities"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : permissions ? (
                <ActivityForm brandId={params.brandId} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
