import React from "react";
import { Tooltip } from "@mui/material";

export const Badge = ({ children, color, classNames = "" }) => {
    const style = {
        color: color,
        backgroundColor: color + "33",
        maxWidth: "120px",
    };

    const textStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
    };

    return (
        <Tooltip title={children} placement="top" arrow>
            <div className={"badge " + classNames} style={style}>
                <span style={textStyle}>{children}</span>
            </div>
        </Tooltip>
    );
};
