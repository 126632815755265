import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Badge, Button, Modal } from "components/ui";

function KidsModalView({ data, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [kid, setKid] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/kids/" + data.kidId,
            method: "GET",
        }).then((response) => {
            setKid(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    const handleRequestApproval = () => {
        sendRequest("accepted");
    };

    const handleRequestReject = () => {
        sendRequest("rejected");
    };

    const sendRequest = (status) => {
        setLoading(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/kids/" + kid.id + "/join",
            method: "POST",
            data: { status: status },
        }).then((response) => {
            closeModal();

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    return (
        <Modal handleCloseModal={closeModal} title={t("Kid information", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Full name", { ns: "common" })}:</strong>
                            {kid.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Group", { ns: "common" })}:</strong>
                            <Badge color={kid.group.color}>{kid.group.name}</Badge>
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Birthday", { ns: "common" })}:</strong>
                            {kid.birthday}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Diet", { ns: "common" })}:</strong>
                            {kid.diet.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Allergy", { ns: "common" })}:</strong>
                            {kid.allergy.map((allergy) => allergy.name).join(", ")}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {kid.birthday}
                        </div>
                    </div>

                    {kid.parents
                        ? kid.parents.map((parent, index) => (
                              <div className="flex flex-col gap-4" key={index}>
                                  <div className="border-t"></div>

                                  <div className="flex flex-col gap-2">
                                      <h4>{t("Parent {{parentNo}}", { ns: "common", parentNo: index + 1 })}</h4>
                                      <div className="flex items-center gap-2">
                                          <strong>{t("Full name", { ns: "common" })}:</strong>
                                          {parent.name}
                                      </div>
                                      <div className="flex items-center gap-2">
                                          <strong>{t("E-mail", { ns: "common" })}:</strong>
                                          {parent.email}
                                      </div>
                                      <div className="flex items-center gap-2">
                                          <strong>{t("Phone", { ns: "common" })}:</strong>
                                          {parent.phone}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : ""}

                    <div className="border-t"></div>

                    <div className="flex flex-col gap-2">
                        <h4>{t("Home address", { ns: "common" })}</h4>
                        <div className="flex items-center gap-2">
                            <strong>{t("Street", { ns: "common" })}:</strong>
                            {kid.street} {kid.number} {kid.premises}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("City", { ns: "common" })}:</strong>
                            {kid.city}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Zip code", { ns: "common" })}:</strong>
                            {kid.zipCode}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Consent for photos of the kid", { ns: "common" })}:</strong>
                            {kid.photoConsent}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Additional information", { ns: "common" })}:</strong>
                            {kid.information}
                        </div>
                    </div>

                    <div className="flex flex-row justify-center gap-2">
                        {kid.joinStatus === "accept" ? (
                            <Link to={"/institution/kids/" + kid.id + "/edit"} className="btn btn-primary">
                                {t("Edit", { ns: "button" })}
                            </Link>
                        ) : kid.joinStatus === "awaiting_approval" ? (
                            <>
                                <Button className={"btn-primary"} onClick={handleRequestApproval}>
                                    {t("Accept", { ns: "button" })}
                                </Button>
                                <Button className={"btn-gray"} onClick={handleRequestReject}>
                                    {t("Reject", { ns: "button" })}
                                </Button>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default KidsModalView;
