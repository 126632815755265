import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const FormatDate = ({ date }) => {
    const { i18n } = useTranslation();

    if (!date) return date;

    const formatting = { timeZone: "UTC" };

    return new Intl.DateTimeFormat(i18n.language, formatting).format(dayjs(date));
};

export const FormatTime = ({ date, formatting = { hour: "numeric", minute: "numeric", second: "numeric" } }) => {
    const { i18n } = useTranslation();

    if (!date) return date;

    formatting.timeZone = "UTC";

    return new Intl.DateTimeFormat(i18n.language, formatting).format(dayjs(date));
};

export const FormatPrice = ({ price }) => {
    const { t } = useTranslation();

    return t("{{price, currency(PLN)}}", { price: price });
};
