import React from "react";
import { FormField } from "./FormField";

export const RadioGroup = ({
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    options,
    ...props
}) => {
    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            {options.map((option) => (
                <div key={option.value}>
                    <input
                        type="radio"
                        className={"form-input " + className}
                        id={option.value}
                        name={name}
                        value={option.value}
                        checked={option.value === value}
                        disabled={option.disabled}
                        {...fieldProps}
                        {...props}
                    />
                    {option.label}
                </div>
            ))}
        </FormField>
    );
};
