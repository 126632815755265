import React from "react";
import { LanguageSwitcher } from "components/ui";

export const Layout = ({ signUpImg, children }) => {
    return (
        <div className="flex h-full gap-8 p-8">
            <div className="flex h-full w-7/12 p-8 bg-bgLight rounded-2xl">{signUpImg}</div>
            <div className="flex flex-col h-full w-5/12 p-12">
                <div className="w-96 m-auto">
                    <div className="flex flex-col gap-6">{children}</div>
                    <div className="mt-6">
                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
        </div>
    );
};
