import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import { useLexicons } from "hooks/useLexicons";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { Button, Modal } from "components/ui";
import { Select2 } from "components/forms";
import { Hidden } from "@mui/material";

function SearchActivityModalEnroll({ data, closeModal }) {
    const { t } = useTranslation();
    const lexicons = useLexicons();

    const [activity, setActivity] = useState(null);
    const [kid, setKid] = useState(null);
    const [paymentFrequencyOptions, setPaymentFrequencyOptions] = useState([]);
    const [enrolled, setEnrolled] = useState(false);
    const [enrolledMessage, setEnrolledMessage] = useState(null);

    const defaultValues = {
        activity: data.activity,
        paymentFrequency: null,
    };

    const validationSchema = Yup.object().shape({
        paymentFrequency: Yup.object().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Frequency of payment", { ns: "common" }),
            })
        ),
    });

    useEffect(() => {
        if (lexicons) {
            setPaymentFrequencyOptions(lexicons["payment"]["frequency"]);
        }

        setActivity(data.activity);
        setKid(data.kid);
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "kids/" + kid.id + "/activities/enroll",
            method: "POST",
            data: values,
        })
            .then((response) => {
                setEnrolled(true);
                setEnrolledMessage(response.data.message);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);

                setTimeout(() => {
                    if (response.data.redirectTo) {
                        window.location.replace(response.data.redirectTo);
                    }
                }, 3000);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleCloseModal={closeModal} title={t("Enroll your kid in an activity", { ns: "common" })}>
            {enrolled ? (
                <>
                    <h3>{enrolledMessage}</h3>
                </>
            ) : activity ? (
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Name of the kid", { ns: "common" })}:</strong>
                                            {kid.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity", { ns: "common" })}:</strong>
                                            {activity.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Brand", { ns: "common" })}:</strong>
                                            {activity.brand.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Date", { ns: "common" })}:</strong>
                                            {activity.period.value === "single" ? (
                                                <FormatDate date={activity.date} />
                                            ) : (
                                                activity.date.map((d) => t(d.name, { ns: "lexicons" })).join(", ")
                                            )}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Hour", { ns: "common" })}:</strong>
                                            <FormatTime
                                                date={activity.time}
                                                formatting={{ hour: "numeric", minute: "numeric" }}
                                            />
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Period", { ns: "common" })}:</strong>
                                            {t(activity.period.name, { ns: "lexicons" })}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Price per activity", { ns: "common" })}:</strong>
                                            <FormatPrice price={activity.price} />
                                        </div>
                                    </div>

                                    <div className="border-t"></div>

                                    <Field
                                        component={Select2}
                                        key={"paymentFrequency"}
                                        name={"paymentFrequency"}
                                        label={t("Frequency of payment", { ns: "common" })}
                                        required={true}
                                        fieldClassName={"col-span-2"}
                                        options={paymentFrequencyOptions}
                                        getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                        // onChange={(option) => {
                                        //     setFieldValue("period", option);
                                        //     setActivityPeriod(option.value);
                                        // }}
                                    />

                                    <div className="border-t"></div>

                                    <div className="flex flex-row justify-center">
                                        {t("Do you want to enroll your kid in a activity?", { ns: "common" })}
                                    </div>

                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Yes", { ns: "button" })}
                                        </Button>
                                        <Button className={"btn-gray"} onClick={closeModal} disabled={isSubmitting}>
                                            {t("No", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default SearchActivityModalEnroll;
