import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Box, ContentHeading } from "components/ui";

function TrainerForm({ brandId, trainer }) {
    const { t } = useTranslation();

    const [activityOptions, setActivityOptions] = useState([]);
    const [contractTypesOptions, setContractTypesOptions] = useState([]);

    const formSchema = [
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-2",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-2",
        },
        {
            name: "birthday",
            label: t("Birthday", { ns: "common" }),
            componentType: "datePicker",
            required: true,
            fieldClassName: "col-span-2",
            maxDate: dayjs(),
            openTo: "year",
        },
        {
            name: "activities",
            label: t("Activities", { ns: "common" }),
            componentType: "select",
            required: true,
            fieldClassName: "col-span-full",
            options: activityOptions,
            isMulti: true,
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "contractType",
            label: t("Contract type", { ns: "common" }),
            componentType: "select",
            required: true,
            fieldClassName: "col-span-3",
            options: contractTypesOptions,
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-4",
        },
        {
            name: "number",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "premises",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "zipCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "description",
            label: t("Description", { ns: "common" }),
            componentType: "textarea",
            required: true,
            fieldClassName: "col-span-full",
        },
        {
            name: "qualifications",
            label: t("Qualifications", { ns: "common" }),
            componentType: "textarea",
            required: true,
            fieldClassName: "col-span-full",
        },
    ];

    useEffect(() => {
        if (brandId) {
            AxiosInstance({
                url: "brands/" + brandId + "/activities",
                method: "GET",
            }).then((response) => {
                setActivityOptions(response.data);
            });

            AxiosInstance({
                url: "contract-types",
                method: "GET",
            }).then((response) => {
                setContractTypesOptions(response.data);
            });
        }
    }, [brandId]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "brands/" + brandId + "/trainers";
        let method = "POST";

        if (trainer) {
            url += "/" + trainer.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!trainer && response.status === 201) {
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {trainer ? t("Edit trainer", { ns: "common" }) : t("Add trainer", { ns: "common" })}
            </ContentHeading>

            <Box>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={trainer}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={"/brand/brands"}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default TrainerForm;
