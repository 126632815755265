import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

function SearchActivityModalView({ data, openModal, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "search/activities/" + data.activity.id,
            method: "GET",
        }).then((response) => {
            setActivity(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    return (
        <Modal handleCloseModal={closeModal} title={t("Information about activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : activity ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {activity.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Brand", { ns: "common" })}:</strong>
                            {activity.brand.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Date", { ns: "common" })}:</strong>
                            {activity.period.value === "single" ? (
                                <FormatDate date={activity.date} />
                            ) : (
                                activity.date.map((d) => t(d.name, { ns: "lexicons" })).join(", ")
                            )}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Hour", { ns: "common" })}:</strong>
                            <FormatTime date={activity.time} formatting={{ hour: "numeric", minute: "numeric" }} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Period", { ns: "common" })}:</strong>
                            {t(activity.period.name, { ns: "lexicons" })}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Price per activity", { ns: "common" })}:</strong>
                            <FormatPrice price={activity.price} />
                        </div>
                    </div>

                    <div className="border-t"></div>

                    <div className="flex flex-row justify-center gap-2">
                        <Button className="btn btn-primary" onClick={() => openModal(activity, "enroll")}>
                            {t("Enroll your kid in an activity", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default SearchActivityModalView;
