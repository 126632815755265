import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import AuthService from "services/AuthService";
import { Step1, Step2 } from "./components";

export const Login = () => {
    const [authenticated, setAuthenticated] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({ phone: "", password: "" });

    useEffect(() => {
        if (AuthService.isToken()) {
            setAuthenticated(true);
        }
    }, []);

    // Handle fields change
    const handleChange = (input) => (e) => {
        let value = "";

        if ("password" === input) {
            value = e;
        } else {
            value = e.target.value;
        }

        setData((data) => {
            return {
                ...data,
                [input]: value,
            };
        });
    };

    const nextStep = () => {
        setMessage(null);
        setSubmitting(true);

        if (1 === step) {
            AuthService.auth(data.phone).then(
                (response) => {
                    if (response.status && response.status === true) {
                        setStep(2);
                        setMessage(null);

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    } else {
                        setMessage(response.message);

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                },
                (error) => {
                    // setMessage(error.response.data.message);

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            );
        } else if (2 === step) {
            AuthService.login(data.phone, data.password).then(
                (response) => {
                    if (response.token) {
                        setStep(3);
                        setMessage(null);

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);

                        window.location.reload();
                    } else {
                        setMessage(response.message);

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                },
                (error) => {
                    if (error.response && Object.keys(error.response.data).length > 0) {
                        setMessage(
                            error.response.data.detail ? error.response.data.detail : error.response.data.message
                        );
                    } else {
                        setMessage(error.message);
                    }

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            );
        }
    };

    const reSendCodeOTP = () => {
        setSubmitting(true);

        AuthService.auth(data.phone).then(
            (response) => {
                if (response.status && response.status === true) {
                    setMessage(null);

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                } else {
                    setMessage(response.message);

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            },
            (error) => {
                setMessage(error.response.data.message);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            }
        );
    };

    const getSectionComponent = () => {
        switch (step) {
            case 1:
                return (
                    <Step1
                        handleChange={handleChange}
                        nextStep={nextStep}
                        message={message}
                        isSubmitting={isSubmitting}
                    />
                );
            case 2:
                return (
                    <Step2
                        handleChange={handleChange}
                        nextStep={nextStep}
                        reSendCodeOTP={reSendCodeOTP}
                        message={message}
                        isSubmitting={isSubmitting}
                    />
                );
        }
    };

    if (authenticated) {
        return <Navigate to="/" replace={true} />;
    } else {
        return getSectionComponent();
    }
};
