import React from "react";

export const ImgWithFallback = ({ src, fallback, className, type = "image/webp", ...delegated }) => {
    return (
        <picture className={className}>
            <source srcSet={src} type={type} />
            <img src={fallback} {...delegated} />
        </picture>
    );
};
