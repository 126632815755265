import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoAddOutline, IoBasketballOutline, IoCreateOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatPrice } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Box, Button, ContentHeading } from "components/ui";

function ActivityList({ brandId }) {
    const { t } = useTranslation();

    const [citiesLoading, setCitiesLoading] = useState(true);
    const [activitiesLoading, setActivitiesLoading] = useState(true);
    const [activeCity, setActiveCity] = useState(0);
    const [cities, setCities] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        AxiosInstance({
            url: "brands/" + brandId + "/cities",
            method: "GET",
        }).then((response) => {
            setCities(response.data);

            setTimeout(() => {
                setCitiesLoading(false);

                if (response.data.length) {
                    loadActivities(response.data[0].id);
                } else {
                    setActivitiesLoading(false);
                }
            }, 300);
        });
    }, [brandId]);

    function loadActivities(city) {
        setActiveCity(city);
        setActivitiesLoading(true);
        AxiosInstance({
            url: "brands/" + brandId + "/cities/" + city + "/activities",
            method: "GET",
        }).then((response) => {
            setActivities(response.data);

            setTimeout(() => {
                setActivitiesLoading(false);
            }, 300);
        });
    }

    return (
        <div className="activities flex flex-col gap-6">
            <ContentHeading
                showBtn={true}
                btnUrl={"/brand/brands/" + brandId + "/activities/new"}
                btnIcon={<IoAddOutline size={"1.125em"} />}
                btnLabel={t("Add activity", { ns: "button" })}
            >
                <IoBasketballOutline size={"1.25rem"} />
                {t("Activity offer", { ns: "common" })}
            </ContentHeading>

            {citiesLoading ? (
                <LoadingSpinner />
            ) : activities.length ? (
                <div className="content-links">
                    {cities.map((city, index) => (
                        <Button
                            className={city.id === activeCity ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => loadActivities(city.id)}
                            key={index}
                        >
                            {city.name}
                        </Button>
                    ))}
                </div>
            ) : (
                ""
            )}

            {activitiesLoading ? (
                <LoadingSpinner />
            ) : activities.length ? (
                <div className="flex gap-6">
                    <div className="flex flex-col gap-4 w-3/12">
                        {activities.map((activity, index) => (
                            <Button
                                className={index === activeActivity ? "btn-primary" : "btn-dark-outline btn-opacity"}
                                onClick={() => setActiveActivity(index)}
                                key={index}
                            >
                                {activity.name}
                            </Button>
                        ))}
                    </div>

                    <Box className={"box-items w-9/12"}>
                        {activities.map((activity, index) => (
                            <div className={"activity " + (index === activeActivity ? "" : "!hidden")} key={index}>
                                <Link to={"/brand/brands/" + brandId + "/activities/" + activity.id + "/edit"}>
                                    <IoCreateOutline size={"1.125em"} />
                                </Link>
                                <h4>{activity.name}</h4>
                                <p>{activity.description}</p>
                                <hr className="mb-4"></hr>
                                <p>
                                    <strong>{t("Territorial scope", { ns: "common" })}:</strong> {activity.territory}
                                </p>
                                <p>
                                    <strong>{t("Price per activity", { ns: "common" })}:</strong>{" "}
                                    <FormatPrice price={activity.price} />
                                </p>
                                <p className="!mb-0">
                                    <strong>{t("Activity length", { ns: "common" })}:</strong> {activity.length}
                                </p>
                            </div>
                        ))}
                    </Box>
                </div>
            ) : (
                t("No activities found", { ns: "common" })
            )}
        </div>
    );
}

export default ActivityList;
