import React from "react";

export const DataEmpty = ({ title, message }) => {
    return (
        <div>
            <h4>{title}</h4>
            {message && <p className="mt-4">{message}</p>}
        </div>
    );
};
