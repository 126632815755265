import React from "react";
import { useTranslation } from "react-i18next";
import AuthCode from "react-auth-code-input";

import { Alert, Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import loginWebP from "../img/login-step-2.webp";
import loginPNG from "../img/login-step-2.png";

export const Step2 = ({ handleChange, nextStep, reSendCodeOTP, message, isSubmitting }) => {
    const { t } = useTranslation();

    const loginImg = (
        <ImgWithFallback src={loginWebP} fallback={loginPNG} className="flex max-w-lg m-auto" alt="Login" />
    );

    return (
        <Layout loginImg={loginImg}>
            <h3>{t("Verification", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("In a moment, you will receive a verification code to the phone number you provided.", {
                    ns: "common",
                })}
            </p>

            {message && <Alert message={message} type="error" />}

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    <label htmlFor="code" className="form-label">
                        {t("Enter the 6-digit verification code", { ns: "common" })}
                    </label>
                    <AuthCode
                        containerClassName="flex gap-2"
                        allowedCharacters="numeric"
                        inputClassName="form-input text-center w-1/6"
                        onChange={handleChange("password")}
                    />
                </div>

                <span className={"text-gray text-sm text-end cursor-pointer"} onClick={reSendCodeOTP}>
                    {t("Resend verification code", { ns: "button" })}
                </span>
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Sign in", { ns: "button" })}
            </Button>
        </Layout>
    );
};
