import React, { createElement } from "react";

import { FormField } from "./FormField";
import { Button, Icons } from "components/ui";

export const IconPicker = ({
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    ...props
}) => {
    const icons = Icons;

    const handleSelectIcon = (icon) => {
        form.setFieldValue(name, icon, true);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <div className={"flex flex-wrap items-center gap-3"}>
                {Object.keys(icons).map((iconKey, index) => (
                    <Button
                        className={"btn-action " + (value === icons[iconKey].name ? "active" : "")}
                        onClick={() => handleSelectIcon(icons[iconKey].name)}
                        key={index}
                    >
                        {createElement(icons[iconKey], { size: "1.5em" })}
                    </Button>
                ))}
            </div>
            <input type="hidden" id={name} name={name} value={value} required={required} {...fieldProps} {...props} />
        </FormField>
    );
};
