import React from "react";

export const FormField = ({ id, label, required, children, className, formProps: { touched, errors } }) => {
    const hasError = errors[id] && touched[id];

    return (
        <div className={"form-field " + className + (hasError ? " field-error" : "")} label={label}>
            <label htmlFor={id} className="form-label">
                {label}
                {required && <sup className="required">*</sup>}
            </label>
            {children}
            {hasError && <small className="error">{errors[id]}</small>}
        </div>
    );
};
