import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";

export const Step4 = ({ handleChange, nextStep, resetSignUp, reSendCodeOTP, message, isSubmitting, data, error }) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("We still need your consents to complete the registration process.", { ns: "common" })}
            </p>

            {message && <Alert message={message} type="error" />}

            <div className="flex flex-col w-full gap-2">
                <label htmlFor="privacyPolicy" className="form-field-checkbox">
                    <input
                        type="checkbox"
                        name="privacyPolicy"
                        id="privacyPolicy"
                        className="form-input"
                        onChange={handleChange("privacyPolicy")}
                        checked={data.privacyPolicy}
                    />
                    <a href="#" target="_blank">
                        {t("Privacy Policy", { ns: "common" })}
                    </a>
                </label>
                <label htmlFor="termsConditions" className="form-field-checkbox">
                    <input
                        type="checkbox"
                        name="termsConditions"
                        id="termsConditions"
                        className="form-input"
                        onChange={handleChange("termsConditions")}
                        checked={data.termsConditions}
                    />
                    <a href="#" target="_blank">
                        {t("Terms and conditions", { ns: "common" })}
                    </a>
                </label>
                <label htmlFor="marketingAgreement" className="form-field-checkbox">
                    <input
                        type="checkbox"
                        name="marketingAgreement"
                        id="marketingAgreement"
                        className="form-input"
                        onChange={handleChange("marketingAgreement")}
                        checked={data.marketingAgreement}
                    />
                    <a href="#" target="_blank">
                        {t("Marketing consents", { ns: "common" })}
                    </a>
                </label>
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>

            {message && (error === "otp_expired" || error === "otp_used") && (
                <Button className={"btn-gray w-full"} onClick={resetSignUp}>
                    {t("Back", { ns: "button" })}
                </Button>
            )}
        </Layout>
    );
};
