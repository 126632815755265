import React from "react";
import { useTranslation } from "react-i18next";

import { AdvancedForm } from "components/forms/AdvancedForm";

function GroupForm({ handleSubmit, group }) {
    const { t } = useTranslation();

    const formSchema = [
        {
            name: "name",
            label: t("Group name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "icon",
            label: t("Group icon", { ns: "common" }),
            componentType: "iconPicker",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-4",
        },
        {
            name: "color",
            label: t("Group color", { ns: "common" }),
            componentType: "colorPicker",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-2",
        },
    ];

    return (
        <>
            <AdvancedForm
                schema={formSchema}
                onSubmit={handleSubmit}
                initialValues={group}
                buttonClassName={"col-span-2"}
                buttonLabel={t("Save", { ns: "button" })}
                submittingButtonLabel={t("Saving", { ns: "button" })}
            />
        </>
    );
}

export default GroupForm;
