import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Alert, Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import loginWebP from "../img/login-step-1.webp";
import loginPNG from "../img/login-step-1.png";

export const Step1 = ({ handleChange, nextStep, message, isSubmitting }) => {
    const { t } = useTranslation();

    const loginImg = (
        <ImgWithFallback src={loginWebP} fallback={loginPNG} className="flex max-w-xl m-auto" alt="Login" />
    );

    return (
        <Layout loginImg={loginImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("Managing children's services and activities has never been so easy!", { ns: "common" })}
            </p>

            {message && <Alert message={message} type="error" />}

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    <label htmlFor="phone" className="form-label">
                        {t("Phone number", { ns: "common" })}
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        id="phone"
                        className="form-input"
                        pattern="[0-9]{9}"
                        maxLength={9}
                        onChange={handleChange("phone")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                    />
                </div>
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>

            <div className="text-center">
                {t("Don't have an account?", { ns: "common" })}
                <Link to={"/signup"} className="text-primary ml-2">
                    {t("Sign up!", { ns: "common" })}
                </Link>
            </div>
        </Layout>
    );
};
