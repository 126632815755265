import React, { useEffect, useState } from "react";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import KidsList from "./components/KidsList";

export const KidsPage = () => {
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [kids, setKids] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/kids",
            method: "GET",
        })
            .then((response) => {
                setKids(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <Dashboard contentClass={"kids"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : kids ? (
                <KidsList kids={kids} handleLoadData={handleLoadData} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
