import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoBusinessOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { Button, ContentHeading } from "components/ui";
import { DataEmpty, LoadingSpinner } from "components/common";
import KidInstitutions from "./KidInstitutions";

function InstitutionList({ kids }) {
    const { t } = useTranslation();
    const [activeKid, setActiveKid] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const [institutions, setInstitutions] = useState(null);

    useEffect(() => {
        if (kids.length) {
            setLoading(true);

            AxiosInstance({
                url: "kids/" + kids[activeKid].id + "/institutions",
                method: "GET",
            })
                .then((response) => {
                    setInstitutions(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setMessage(error.response.data.message);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [activeKid]);

    return (
        <>
            <ContentHeading tag="h3">
                <IoBusinessOutline size={"1.5rem"} />
                {t("Kid institution", { ns: "common" })}
            </ContentHeading>

            {kids.length > 1 && (
                <div className="content-links">
                    {kids.map((kid, index) => (
                        <Button
                            className={index === activeKid ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => setActiveKid(index)}
                            key={index}
                        >
                            {kid.name}
                        </Button>
                    ))}
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
            ) : institutions ? (
                <KidInstitutions kidId={kids[activeKid].id} institutions={institutions} />
            ) : (
                <DataEmpty title={t("Kid's institution not found", { ns: "common" })} message={message} />
            )}
        </>
    );
}

export default InstitutionList;
