import axios from "axios";
import { enqueueSnackbar } from "notistack";

import AuthHeader from "services/AuthHeader";
import AuthService from "services/AuthService";

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_APP_API_URL,
    timeout: 5000,
    headers: AuthHeader(),
});

AxiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.code === "ERR_BAD_REQUEST" && error.response.status === 401 && error.config.url !== "login") {
            AuthService.logout();
            window.location.reload();
        } else {
            let message = "";

            if (error.response && Object.keys(error.response.data).length > 0) {
                message = error.response.data.detail ? error.response.data.detail : error.response.data.message;
            } else {
                message = error.message;
            }

            enqueueSnackbar(message, {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
        }

        return Promise.reject(error);
    }
);

export default AxiosInstance;
