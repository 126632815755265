import React from "react";

import { FormField } from "./FormField";
import { HexColorPicker } from "react-colorful";

export const ColorPicker = ({
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    ...props
}) => {
    const style = {
        width: "100%",
    };

    const handleChangeColor = (color) => {
        form.setFieldValue(name, color, true);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <HexColorPicker color={value} onChange={handleChangeColor} style={style} />
            <input type="hidden" id={name} name={name} value={value} {...fieldProps} {...props} />
        </FormField>
    );
};
