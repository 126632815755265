import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

export const PaymentStatusPage = () => {
    const params = useParams();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            AxiosInstance({
                url: "payment-status/" + params.orderId,
                method: "GET",
            })
                .then((response) => {
                    setStatus(response.data);

                    setTimeout(() => {
                        if (response.data === "COMPLETED") {
                            setLoading(false);
                        }
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        };

        fetchData();

        const intervalId = setInterval(() => {
            if (status === "PENDING") fetchData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Dashboard contentClass={"kids"}>
            {isLoading || status === "PENDING" ? (
                <LoadingSpinner>
                    <h3>{t("Waiting for confirmation of payment", { ns: "common" })}</h3>
                </LoadingSpinner>
            ) : status === "COMPLETED" ? (
                <div className="flex flex-col gap-4 h-full m-auto justify-center items-center">
                    <div className="text-green-600">
                        <IoCheckmarkCircleOutline size={"3rem"} />
                    </div>
                    <h3>{t("Paid", { ns: "common" })}</h3>
                </div>
            ) : status === "COMPLETED" ? (
                <div className="flex flex-col h-full">
                    <div className="text-red-600">
                        <IoCloseCircleOutline size={"3rem"} />
                    </div>
                    <h3>{t("Unpaid", { ns: "common" })}</h3>
                </div>
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
