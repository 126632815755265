import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
    },
});

export const MuiThemeStyle = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
