import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import {
    Checkbox,
    CheckboxSwitch,
    ColorPicker,
    ConditionalField,
    DatePicker,
    Heading,
    IconPicker,
    RadioGroup,
    Select2,
    TextArea,
    TextField,
    TimePicker,
} from ".";

import { Button } from "components/ui";
import { getInitialValues, getDefaultValues, useValidationSchema } from "./helpers";

const components = [
    { componentType: "heading", component: Heading },
    { componentType: "checkbox", component: Checkbox },
    { componentType: "checkboxSwitch", component: CheckboxSwitch },
    { componentType: "colorPicker", component: ColorPicker },
    { componentType: "datePicker", component: DatePicker },
    { componentType: "iconPicker", component: IconPicker },
    { componentType: "radioGroup", component: RadioGroup },
    { componentType: "select", component: Select2 },
    { componentType: "text", component: TextField },
    { componentType: "textarea", component: TextArea },
    { componentType: "time", component: TimePicker },
];

export const AdvancedForm = ({
    schema,
    onSubmit,
    onChange,
    initialValues,
    onClose,
    buttonClassName = "",
    buttonLabel = "Submit",
    submittingButtonLabel = "Sending...",
    cancelButtonLink = "",
    cancelButtonLabel = "Cancel",
    ...props
}) => {
    const defaultValues = getDefaultValues(schema);
    const validationSchema = useValidationSchema(schema);

    return (
        <Formik
            initialValues={getInitialValues(defaultValues, initialValues)}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, setSubmitting, resetForm);
            }}
            validateOnMount
            {...props}
        >
            {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                return (
                    <form onSubmit={handleSubmit} className="advanced-form">
                        <div className="grid grid-cols-6 gap-4">
                            {schema.map(({ componentType, condition, ...formSchema }) => {
                                if (!components.some((component) => component.componentType === componentType)) {
                                    return null;
                                }

                                const Component = components.find(
                                    (component) => component.componentType === componentType
                                ).component;

                                if (condition) {
                                    return (
                                        <ConditionalField
                                            key={formSchema.name}
                                            show={
                                                condition.operator === "="
                                                    ? values[condition.key] === condition.value
                                                    : values[condition.key] !== condition.value
                                            }
                                            onCollapse={() => {
                                                setFieldValue(formSchema.name, defaultValues[formSchema.name]);
                                                setFieldTouched(formSchema.name, false);
                                            }}
                                            onShow={() => {
                                                setFieldValue(formSchema.name, defaultValues[formSchema.name]);
                                            }}
                                        >
                                            <Field component={Component} {...formSchema} />
                                        </ConditionalField>
                                    );
                                }

                                return (
                                    <Field
                                        key={formSchema.name}
                                        component={Component}
                                        className={formSchema.className}
                                        {...formSchema}
                                    />
                                );
                            })}

                            <Button
                                type={"submit"}
                                className={"btn-primary col-start-1 " + buttonClassName}
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting ? submittingButtonLabel : buttonLabel}
                            </Button>

                            {cancelButtonLink ? (
                                <Link to={cancelButtonLink} className={"btn btn-gray"} disabled={isSubmitting}>
                                    {cancelButtonLabel}
                                </Link>
                            ) : (
                                ""
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};
