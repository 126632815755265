import React, { Profiler, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getProfileData, logProfileData } from "utils/profiler";

import { useSessionStorage } from "hooks/useSessionStorage";
import AuthService from "services/AuthService";

import { ChooseAccountType } from "./ChooseAccountType";
import { ParentStep1, ParentStep2, ParentStep3, ParentStep4 } from "./ParentSteps/components";
import { BrandStep1, BrandStep2, BrandStep3, BrandStep4 } from "./BrandSteps/components";

export const SignUp = ({ type }) => {
    const [signUpData, setSignUpData] = useSessionStorage("signUpData", null);

    const [authenticated, setAuthenticated] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (AuthService.isToken()) {
            setAuthenticated(true);
        }

        if (type === null) {
            setSignUpData(null);
        } else if (signUpData === null) {
            setSignUpData({ type: type, step: 1, data: { type: type } });
        }
    }, [signUpData, setSignUpData, type]);

    // Handle fields change
    const handleChange = (input) => (e) => {
        let value = "";

        if ("code" === input) {
            value = e;
        } else if ("checkbox" === e.target.type) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setSignUpData((signUpData) => {
            return {
                ...signUpData,
                data: {
                    ...signUpData.data,
                    [input]: value,
                },
            };
        });
    };

    const nextStep = () => {
        setMessage(null);
        setSubmitting(true);

        AuthService.signup(signUpData.step, signUpData.data).then(
            (response) => {
                if (response.status && response.status === true) {
                    if (4 === signUpData.step && response.token) {
                        sessionStorage.setItem("uid", response.token);
                        sessionStorage.removeItem("signUpData");
                        window.location.reload();
                        return;
                    }

                    setSignUpData({ ...signUpData, step: signUpData.step + 1 });
                    setMessage(null);

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                } else {
                    setMessage(response.message);

                    if (response.error) {
                        setSignUpData({ ...signUpData, error: response.error });
                    }

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            },
            (error) => {
                if (error.response && Object.keys(error.response.data).length > 0) {
                    setMessage(error.response.data.detail ? error.response.data.detail : error.response.data.message);
                } else {
                    setMessage(error.message);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            }
        );
    };

    const resetSignUp = () => {
        setSignUpData({ type: type, step: 1, data: {} });
        setMessage(null);
    };

    const reSendCodeOTP = () => {
        setSubmitting(true);

        AuthService.signup(1, signUpData.data).then(
            (response) => {
                if (response.status && response.status === true) {
                    setSignUpData({ ...signUpData, error: null });
                    setMessage(null);

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                } else {
                    setMessage(response.message);

                    if (response.error) {
                        setSignUpData({ ...signUpData, error: response.error });
                    }

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            },
            (error) => {
                setMessage(error.response.data.message);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            }
        );
    };

    const getSectionComponent = () => {
        if (signUpData === null) {
            return <ChooseAccountType />;
        } else if (signUpData.type === 1 || signUpData.type === 2) {
            switch (signUpData.step) {
                case 1:
                default:
                    return (
                        <ParentStep1
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 2:
                    return (
                        <ParentStep2
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            reSendCodeOTP={reSendCodeOTP}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 3:
                    return (
                        <ParentStep3
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 4:
                    return (
                        <ParentStep4
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
            }
        } else if (signUpData.type === 3) {
            switch (signUpData.step) {
                case 1:
                default:
                    return (
                        <BrandStep1
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 2:
                    return (
                        <BrandStep2
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            reSendCodeOTP={reSendCodeOTP}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 3:
                    return (
                        <BrandStep3
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 4:
                    return (
                        <BrandStep4
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            message={message}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
            }
        }
    };

    if (authenticated) {
        return <Navigate to="/" replace={true} />;
    } else {
        return (
            <Profiler id="SignUp" onRender={(...profileData) => logProfileData(getProfileData(profileData))}>
                {getSectionComponent()}
            </Profiler>
        );
    }
};
