import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./scss/style.scss";
import "./App.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/pl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { SnackbarProvider } from "notistack";

import AuthService from "services/AuthService";
import CurrentUser from "services/CurrentUser";
import { PageNotFound } from "components/common";

import { Login } from "pages/Login";
import { SignUp } from "pages/SignUp/SignUp";

import HomePage from "pages/Home/HomePage";

import {
    ActivityAddPage,
    ActivityEditPage,
    BrandsPage,
    BrandAddPage,
    BrandEditPage,
    TrainerAddPage,
    TrainerEditPage,
} from "pages/Brand";

import { KidsPage, KidAddPage, KidEditPage, InstitutionsPage } from "pages/Parent";

import {
    InstitutionPage,
    InstitutionAddPage,
    InstitutionEditPage,
    KidsPage as InstitutionKidsPage,
    KidAddPage as InstitutionKidAddPage,
    KidEditPage as InstitutionKidEditPage,
    TeachersPage,
    TeacherAddPage,
    TeacherEditPage,
} from "pages/Institution";
import { ActivitiesPage } from "pages/Parent/ActivitiesPage";
import { PaymentStatusPage } from "pages/Parent/PaymentStatusPage";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

function App() {
    const { i18n } = useTranslation();
    const location = useLocation();
    const currentUser = CurrentUser();

    const allowedSlugs = ["/login", "/signup", "/signup/parent", "/signup/institution", "/signup/brand"];

    dayjs.locale(i18n.language);

    if (!AuthService.isToken() && !allowedSlugs.includes(location.pathname)) {
        return <Navigate to="/login" replace={true} />;
    } else if (currentUser != null && currentUser.accountType === "institution") {
        if (currentUser.institution === null && location.pathname !== "/institution/new") {
            return <Navigate to={"/institution/new"} replace={true} />;
        } else if (currentUser.institution != null && location.pathname === "/institution/new") {
            return <Navigate to={"/institution/my"} replace={true} />;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <Routes>
                    <Route exact path="login" element={<Login />} />
                    <Route exact path="signup" element={<SignUp type={null} />} />
                    <Route exact path="signup/parent" element={<SignUp type={1} />} />
                    <Route exact path="signup/institution" element={<SignUp type={2} />} />
                    <Route exact path="signup/brand" element={<SignUp type={3} />} />

                    <Route path="/" element={<HomePage />} />

                    {currentUser && (
                        <>
                            {currentUser.accountType === "brand" && (
                                <>
                                    <Route path="brand/brands" element={<BrandsPage />} />
                                    <Route path="brand/brands/new" element={<BrandAddPage />} />
                                    <Route path="brand/brands/:brandId/edit" element={<BrandEditPage />} />
                                    <Route path="brand/brands/:brandId/activities/new" element={<ActivityAddPage />} />
                                    <Route
                                        path="brand/brands/:brandId/activities/:activityId/edit"
                                        element={<ActivityEditPage />}
                                    />
                                    <Route path="brand/brands/:brandId/trainers/new" element={<TrainerAddPage />} />
                                    <Route
                                        path="brand/brands/:brandId/trainers/:trainerId/edit"
                                        element={<TrainerEditPage />}
                                    />
                                </>
                            )}

                            {currentUser.accountType === "institution" && (
                                <>
                                    <Route path="institution/my" element={<InstitutionPage />} />
                                    <Route path="institution/new" element={<InstitutionAddPage />} />
                                    <Route path="institution/edit" element={<InstitutionEditPage />} />
                                    <Route path="institution/kids" element={<InstitutionKidsPage />} />
                                    <Route path="institution/kids/new" element={<InstitutionKidAddPage />} />
                                    <Route path="institution/kids/:kidId/edit" element={<InstitutionKidEditPage />} />

                                    <Route path="institution/teachers" element={<TeachersPage />} />
                                    <Route path="institution/teachers/new" element={<TeacherAddPage />} />
                                    <Route path="institution/teachers/:teacherId/edit" element={<TeacherEditPage />} />
                                </>
                            )}

                            {currentUser.accountType === "parent" && (
                                <>
                                    <Route path="parent/kids" element={<KidsPage />} />
                                    <Route path="parent/kids/new" element={<KidAddPage />} />
                                    <Route path="parent/kids/:kidId/edit" element={<KidEditPage />} />
                                    <Route path="parent/institutions" element={<InstitutionsPage />} />
                                    <Route path="parent/activities" element={<ActivitiesPage />} />
                                    <Route path="parent/payment-status/:orderId" element={<PaymentStatusPage />} />
                                </>
                            )}
                        </>
                    )}

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </SnackbarProvider>
        </LocalizationProvider>
    );
}

export default App;
