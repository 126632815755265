import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        ns: ["button", "common", "lexicons", "menu", "roles", "validation", "server"],
        defaultNS: "common",
        debug: true,
        fallbackLng: false,
        // fallbackLng: "en",
        // saveMissing: true,
        supportedLngs: ["en", "pl"],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        detection: {},
    });

export default i18n;
