import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { LoadRoles } from "utils/LoadRoles";
import { LoadingSpinner } from "components/common";
import { Modal, RoleBadge } from "components/ui";

function TrainerModalRole({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const roleOptions = LoadRoles("provider");
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [trainer, setTrainer] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "brands/" + data.brandId + "/trainers/" + data.trainerId,
            method: "GET",
        }).then((response) => {
            setTrainer(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    const handelChangeRole = (event) => {
        event.preventDefault();
        setSubmitting(true);

        AxiosInstance({
            url: "users/" + trainer.id + "/add-role",
            method: "POST",
            data: event.target.userRole.value,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    setTrainer(response.data);
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleCloseModal={closeModal} title={t("Edit role", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : trainer.id ? (
                <div className="flex flex-col gap-4 w-full">
                    <form onSubmit={handelChangeRole}>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-2">
                                <strong>{t("Trainer", { ns: "common" })}:</strong>
                                {trainer.fullName}
                            </div>
                            <div className="flex items-center gap-2">
                                <strong>{t("Roles", { ns: "common" })}:</strong>
                                {trainer.roles.map((role, index) => (
                                    <RoleBadge role={role} key={index} />
                                ))}
                            </div>
                            <div className="flex gap-2">
                                <div className="border-t w-full"></div>
                            </div>
                            <div className="flex gap-2">
                                <strong>{t("Add role", { ns: "common" })}:</strong>
                            </div>
                            <div className="flex gap-2">
                                <Select
                                    placeholder={t("Select...", { ns: "common" })}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.key}
                                    options={roleOptions}
                                    className="form-select w-full"
                                    name="userRole"
                                />
                            </div>
                            <div className="flex justify-center gap-2">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                    {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                </button>
                                <button type="button" className="btn btn-gray" onClick={closeModal}>
                                    {t("Cancel", { ns: "button" })}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default TrainerModalRole;
