import React from "react";

export const Box = ({ className = "", children, title = null }) => {
    return (
        <div className={"box " + className}>
            {title && <h6 className="font-normal uppercase">{title}</h6>}
            {children}
        </div>
    );
};
