import React, { useEffect, useState } from "react";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import KidForm from "./components/KidForm";

export const KidAddPage = () => {
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/kids",
            method: "GET",
        })
            .then((response) => {
                setPermissions(true);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"kids"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : permissions ? (
                <KidForm cancelButtonLink="/institution/kids" />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
