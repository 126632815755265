import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useField } from "formik";
import { FormField } from "./FormField";

export const Select2 = ({
    label,
    fieldClassName,
    field: { name, value },
    form,
    required,
    options,
    isMulti = false,
    ...props
}) => {
    const { t } = useTranslation();
    const [field, state, { setValue, setTouched }] = useField(name);

    const onChange = (value) => {
        form.setFieldValue(name, isMulti ? value.map((v) => v) : value);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <Select
                options={options}
                getOptionLabel={(option) => t(option.name, { ns: "common" })}
                getOptionValue={(option) => (option.value ? option.value : option.id)}
                classNames={{
                    control: (state) => "form-select",
                }}
                placeholder={t("Select...", { ns: "common" })}
                value={value}
                defaultValue={value}
                name={name}
                id={name}
                onChange={onChange}
                isMulti={isMulti}
                onBlur={setTouched}
                {...props}
            />
        </FormField>
    );
};
