import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const getInitialValues = (defaultValues, initialValues) => {
    if (!initialValues) return defaultValues;

    return { ...defaultValues, ...initialValues };
};

export const getDefaultValues = (schema) => {
    return schema
        .filter((val) => !val.condition)
        .reduce((acc, val) => {
            let defaultValue;

            if (val.componentType === "select" && val.isMulti) {
                defaultValue = [];
            } else {
                switch (val.componentType) {
                    case "text":
                    case "textarea":
                    case "select":
                    case "radioGroup":
                        defaultValue = "";
                        break;
                    case "checkbox":
                    case "checkboxSwitch":
                        defaultValue = false;
                        break;
                    case "datePicker":
                    case "time":
                        defaultValue = null;
                        break;
                    default:
                        defaultValue = "";
                }
            }

            return {
                ...acc,
                [val.name]: val.defaultValue || defaultValue,
            };
        }, {});
};

export const useValidationSchema = (schema) => {
    const { t } = useTranslation("validation");

    const validationObject = schema.reduce((acc, val) => {
        let validationType;

        if (val.componentType === "select" && val.isMulti) {
            validationType = Yup.array();

            if (val.required && validationType) {
                validationType = validationType.min(1, t("The {{label}} field is required.", { label: val.label }));
            }
        } else {
            switch (val.componentType) {
                case "text":
                case "textarea":
                case "radioGroup":
                    validationType = Yup.string();
                    break;
                case "select":
                    validationType = Yup.object();
                    break;
                case "datePicker":
                    validationType = Yup.date();
                    break;
                case "checkbox":
                case "switch":
                default:
                    validationType = null;
            }

            if (val.required && validationType) {
                validationType = validationType.required(t("The {{label}} field is required.", { label: val.label }));
            }
        }

        return { ...acc, ...(validationType && { [val.name]: validationType }) };
    }, {});

    return Yup.object().shape(validationObject);
};
