import React from "react";
import { useTranslation } from "react-i18next";

import CurrentUser from "services/CurrentUser";
import { Notifications } from "./Notifications";
import { Avatar, LanguageSwitcher } from "components/ui";

export const Navbar = () => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    return (
        <>
            {currentUser && (
                <div className="navbar">
                    <div className="navbar-content">
                        <div className="navbar-left">
                            <div className="inline-flex ml-24 text-xs text-gray-500 gap-3">
                                <span>{currentUser.id}</span>
                                <span>{currentUser.fullName}</span>
                                <span>{currentUser.roles}</span>
                            </div>
                        </div>

                        <div className="grow"></div>

                        <LanguageSwitcher onlyFlag={true} />

                        <Notifications />

                        <Avatar avatar={currentUser.avatar} />
                    </div>
                </div>
            )}
        </>
    );
};
