import React from "react";

import { IconComponent } from "components/ui";

export const GroupIcon = ({ icon, color, size, className }) => {
    const style = {
        color: color,
        backgroundColor: color + "33",
    };

    return (
        icon && (
            <div className={"rounded-full p-2 " + className} style={style}>
                <IconComponent icon={icon} size={size} />
            </div>
        )
    );
};
