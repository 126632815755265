import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import { FormField } from "./FormField";

export const CheckboxSwitch = ({
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    ...props
}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        form.setFieldValue(name, event.target.checked);
    };

    return (
        <FormField
            id={name}
            label={label}
            required={required}
            className={"!flex-row items-center !gap-4 " + fieldClassName}
            formProps={form}
        >
            <div className="flex items-center">
                <span className="text-sm">{t("no", { ns: "common" })}</span>
                <Switch value={1} checked={value} onChange={handleChange} />
                <span className="text-sm">{t("yes", { ns: "common" })}</span>
            </div>
        </FormField>
    );
};
