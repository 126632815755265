import AxiosInstance from "utils/AxiosInstance";

class AuthService {
    signup(step, data) {
        return AxiosInstance({
            url: "signup/step-" + step,
            method: "POST",
            data: data,
        }).then((response) => {
            return response.data;
        });
    }

    auth(phone) {
        return AxiosInstance({
            url: "auth",
            method: "POST",
            data: { phone: phone },
        }).then((response) => {
            return response.data;
        });
    }

    login(phone, code) {
        return AxiosInstance({
            url: "login",
            method: "POST",
            data: { phone: phone, password: code },
        }).then((response) => {
            if (response.data.token) {
                sessionStorage.setItem("uid", response.data.token);
            }

            return response.data;
        });
    }

    logout() {
        sessionStorage.clear();
    }

    isToken() {
        if (sessionStorage.getItem("uid")) return true;

        return false;
    }
}

export default new AuthService();
