import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import ActivityForm from "./components/ActivityForm";

export const ActivityEditPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        if (params.activityId) {
            AxiosInstance({
                url: "brands/" + params.brandId + "/activities/" + params.activityId,
                method: "GET",
            })
                .then((response) => {
                    setActivity(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        }
    }, []);

    return (
        <Dashboard contentClass={"activities"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : activity ? (
                <ActivityForm brandId={params.brandId} activity={activity} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
