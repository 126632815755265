import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import BrandForm from "./components/BrandForm";

export const BrandAddPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        AxiosInstance({
            url: "brands",
            method: "GET",
        })
            .then((response) => {
                setPermissions(true);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"brands"}>
            {isLoading ? <LoadingSpinner /> : permissions ? <BrandForm /> : <AccessDenied />}
        </Dashboard>
    );
};
