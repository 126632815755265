import React, { Component, createElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoAddOutline, IoCreateOutline, IoListOutline, IoPeopleOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { Box, Button } from "components/ui";
import { AccessDenied, LoadingSpinner } from "components/common";
import { GroupIcon } from "./GroupIcon";
import GroupAddModal from "./GroupAddModal";
import GroupEditModal from "./GroupEditModal";

function GroupsList({ institution }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [teacherTypes, setTeacherTypes] = useState([]);

    useEffect(() => {
        AxiosInstance({
            url: "teacher-types",
            method: "GET",
        }).then((response) => {
            setTeacherTypes(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });

        handleLoadGroups();
    }, []);

    const handleLoadGroups = () => {
        AxiosInstance({
            url: "institutions/" + institution.id + "/groups",
            method: "GET",
        })
            .then((response) => {
                setGroups(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const openModal = (groupId = null, type = "view") => {
        if (groupId === null) {
            setModal({ show: true, data: null, type: type });
        } else {
            setModal({ show: true, data: { groupId: groupId }, type: type });
        }
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <div className={"content-heading"}>
                <h5 className="flex items-center gap-3">
                    <IoPeopleOutline size={"1.5rem"} />
                    {t("Groups list", { ns: "common" })}
                </h5>
                <Button className={"btn btn-primary"} onClick={() => openModal(null, "add")}>
                    <IoAddOutline size={"1.125rem"} />
                    {t("Add group", { ns: "button" })}
                </Button>
            </div>

            {isLoading ? (
                <LoadingSpinner />
            ) : groups ? (
                <div className="grid grid-cols-3 gap-6">
                    {groups.map((group, index) => (
                        <Box className={"box-items relative"} key={index}>
                            <div className="flex gap-2 absolute top-4 right-4">
                                <Button className={"btn-action"} onClick={() => openModal(group.id, "edit")}>
                                    <IoCreateOutline size={"1.125em"} />
                                </Button>
                            </div>

                            <h3 className="flex items-center gap-4">
                                <GroupIcon icon={group.icon} color={group.color} size={"1em"} />
                                {group.name}
                            </h3>

                            {teacherTypes.map((teacherType, typeIndex) => (
                                <div className="box-item" key={typeIndex}>
                                    <strong>{teacherType.name}:</strong>
                                    {group.teachers
                                        .filter(function (teacher) {
                                            if (teacher.typeId != teacherType.id) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .map(function (teacher) {
                                            return teacher.teacherName;
                                        })
                                        .join(", ")}
                                </div>
                            ))}

                            <div className="box-item">
                                <strong>{t("Number of kids", { ns: "common" })}:</strong>
                                {group.numberKids}
                            </div>

                            <Link to={"/institution/kids/?group=" + group.name} className={"btn btn-sm btn-primary"}>
                                <IoListOutline size={"1.5em"} />
                                {t("Kids list", { ns: "button" })}
                            </Link>
                        </Box>
                    ))}
                </div>
            ) : (
                <AccessDenied />
            )}

            {modal.show && modal.type === "add" && (
                <GroupAddModal closeModal={handleModalClose} handleLoadGroups={handleLoadGroups} />
            )}

            {modal.show && modal.data && modal.type === "edit" && (
                <GroupEditModal data={modal.data} closeModal={handleModalClose} handleLoadGroups={handleLoadGroups} />
            )}
        </>
    );
}

export default GroupsList;
