import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import { useLexicons } from "hooks/useLexicons";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Box, Button } from "components/ui";
import { TextField, Select2, PriceField, DatePicker, TimePicker } from "components/forms";
import SearchActivityResult from "./SearchActivityResult";

function SearchActivityForm({ kid, setActiveSearch }) {
    const { t } = useTranslation();
    const lexicons = useLexicons();

    const [isLoading, setLoading] = useState(true);
    const [isLoadingResult, setLoadingResult] = useState(false);
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [activities, setActivities] = useState(null);
    const [activityCategoriesOptions, setActivityCategoriesOptions] = useState([]);
    const [activityTypesOptions, setActivityTypesOptions] = useState([]);
    const [activityCityOptions, setActivityCityOptions] = useState([]);
    const [activityOrganizerOptions, setActivityOrganizerOptions] = useState([]);
    const [activityPeriodOptions, setActivityPeriodOptions] = useState([]);
    const [activityPeriod, setActivityPeriod] = useState(null);

    const defaultValues = {
        name: "",
        category: null,
        type: null,
        city: null,
        organizer: null,
        brand: "",
        trainer: "",
        priceFrom: "",
        priceTo: "",
        period: null,
        date: null,
        timeFrom: null,
        timeTo: null,
    };

    useEffect(() => {
        if (lexicons) {
            setActivityPeriodOptions(lexicons["activity"]["period"]);
        }

        Promise.all([
            AxiosInstance({
                url: "week-days",
                method: "GET",
            }).then((response) => {
                setWeekDaysOptions(response.data);
            }),

            AxiosInstance({
                url: "activity-cities",
                method: "GET",
            }).then((response) => {
                setActivityCityOptions(response.data);
            }),

            AxiosInstance({
                url: "activity-categories",
                method: "GET",
            }).then((response) => {
                setActivityCategoriesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    const handleLoadTypeByCategory = async (categoryId) => {
        AxiosInstance({
            url: "activity-types/" + categoryId,
            method: "GET",
        }).then((response) => {
            setActivityTypesOptions(response.data);
        });
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setActivities(null);
        setSubmitting(true);
        setLoadingResult(true);

        AxiosInstance({
            url: "search/activities",
            method: "POST",
            data: values,
        })
            .then((response) => {
                setActivities(response.data.data);

                setTimeout(() => {
                    setSubmitting(false);
                    setLoadingResult(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                    setLoadingResult(false);
                }, 300);
            });
    };

    const validationSchema = Yup.object().shape({});

    return (
        <>
            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Formik
                        initialValues={defaultValues}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                            return (
                                <Form>
                                    <div className="grid grid-cols-6 gap-6">
                                        <Field
                                            component={TextField}
                                            key={"name"}
                                            name={"name"}
                                            label={t("Search by name", { ns: "common" })}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"category"}
                                            name={"category"}
                                            label={t("Category", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                            options={activityCategoriesOptions}
                                            onChange={(val) => {
                                                setFieldValue("category", val);
                                                setFieldValue("type", "");
                                                handleLoadTypeByCategory(val.id);
                                            }}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"type"}
                                            name={"type"}
                                            label={t("Type", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                            options={activityTypesOptions}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"city"}
                                            name={"city"}
                                            label={t("City", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                            options={activityCityOptions}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"organizer"}
                                            name={"organizer"}
                                            label={t("Organizer", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                            options={activityOrganizerOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                        />
                                        <Field
                                            component={TextField}
                                            key={"brand"}
                                            name={"brand"}
                                            label={t("Brand", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                        />
                                        <Field
                                            component={TextField}
                                            key={"trainer"}
                                            name={"trainer"}
                                            label={t("Name of the trainer", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                        />
                                        <Field
                                            component={PriceField}
                                            key={"priceFrom"}
                                            name={"priceFrom"}
                                            label={t("Price from", { ns: "common" })}
                                            fieldClassName={"col-start-1 col-span-3"}
                                        />
                                        <Field
                                            component={PriceField}
                                            key={"priceTo"}
                                            name={"priceTo"}
                                            label={t("Price to", { ns: "common" })}
                                            fieldClassName={"col-span-3"}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"period"}
                                            name={"period"}
                                            label={t("Period", { ns: "common" })}
                                            fieldClassName={"col-span-2"}
                                            options={activityPeriodOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            onChange={(option) => {
                                                setFieldValue("period", option);
                                                setActivityPeriod(option.value);
                                            }}
                                        />
                                        {activityPeriod === "SINGLE" ? (
                                            <Field
                                                component={DatePicker}
                                                key={"date"}
                                                name={"date"}
                                                minDate={dayjs()}
                                                label={t("Date of activity", { ns: "common" })}
                                                fieldClassName={"col-span-2"}
                                            />
                                        ) : activityPeriod === "CYCLICAL" ? (
                                            <Field
                                                component={Select2}
                                                key={"date"}
                                                name={"date"}
                                                label={t("Days of the week", { ns: "common" })}
                                                isMulti={true}
                                                fieldClassName={"col-span-2"}
                                                options={weekDaysOptions}
                                                getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <Field
                                            component={TimePicker}
                                            key={"timeFrom"}
                                            name={"timeFrom"}
                                            label={t("Hour from", { ns: "common" })}
                                            fieldClassName={"col-span-1"}
                                        />
                                        <Field
                                            component={TimePicker}
                                            key={"timeTo"}
                                            name={"timeTo"}
                                            label={t("Hour to", { ns: "common" })}
                                            fieldClassName={"col-span-1"}
                                        />
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary col-start-1"}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting
                                                ? t("Searching", { ns: "button" })
                                                : t("Search", { ns: "button" })}
                                        </Button>
                                        <Button
                                            className="btn-gray"
                                            disabled={isSubmitting}
                                            onClick={() => setActiveSearch(false)}
                                        >
                                            {t("Cancel", { ns: "button" })}
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Box>

            {isLoadingResult ? (
                <LoadingSpinner />
            ) : activities ? (
                <SearchActivityResult kid={kid} activities={activities} />
            ) : (
                ""
            )}
        </>
    );
}

export default SearchActivityForm;
