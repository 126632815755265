import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoAccessibilityOutline, IoChevronDownOutline, IoChevronUpOutline, IoEyeOutline } from "react-icons/io5";
import { DataGrid } from "@mui/x-data-grid";

import CurrentUser from "services/CurrentUser";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { Button, ContentHeading } from "components/ui";
import SearchActivityModalView from "./SearchActivityModalView";
import SearchActivityModalEnroll from "./SearchActivityModalEnroll";

function SearchActivityResult({ kid, activities }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [filterModel, setFilterModel] = useState({ items: [] });

    const columns = [
        { field: "name", headerName: t("Activity", { ns: "common" }), flex: 1 },
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            flex: 1,
            valueGetter: (params) => `${params.row.brand.name || ""}`,
        },
        {
            field: "price",
            headerName: t("Price per activity", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return <FormatPrice price={params.row.price} />;
            },
        },
        {
            field: "date",
            headerName: t("Date of activity", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return params.row.period.value === "single" ? (
                    <FormatDate date={params.row.date} />
                ) : (
                    params.row.date.map((d) => t(d.name, { ns: "lexicons" })).join(", ")
                );
            },
        },
        {
            field: "time",
            headerName: t("Hour", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return <FormatTime date={params.row.time} formatting={{ hour: "numeric", minute: "numeric" }} />;
            },
        },
        { field: "length", headerName: t("Activity length", { ns: "common" }), width: 180 },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button className={"btn-action"} onClick={() => openModal(params.row, "view")}>
                            <IoEyeOutline size={"1.25rem"} />
                        </Button>
                        <Button className={"btn-action"} onClick={() => openModal(params.row, "enroll")}>
                            <IoAccessibilityOutline size={"1.25rem"} />
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {}, [searchParams]);

    const openModal = (activity, type = "view") => {
        setModal({ show: true, data: { activity: activity, kid: kid }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">{t("Search results", { ns: "common" })}</ContentHeading>

            <div className="table-box" style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={activities}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    filterModel={filterModel}
                    pageSizeOptions={[10, 25, 50]}
                    disableColumnMenu={true}
                    width={"100%"}
                    slots={{
                        // toolbar: GridToolbar,
                        columnSortedDescendingIcon: IoChevronUpOutline,
                        columnSortedAscendingIcon: IoChevronDownOutline,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </div>

            {modal.show && modal.data && modal.type === "view" && (
                <SearchActivityModalView data={modal.data} openModal={openModal} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "enroll" && (
                <SearchActivityModalEnroll data={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default SearchActivityResult;
