import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import TrainerForm from "./components/TrainerForm";

export const TrainerEditPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [trainer, setTrainer] = useState(null);

    useEffect(() => {
        if (params.trainerId) {
            AxiosInstance({
                url: "brands/" + params.brandId + "/trainers/" + params.trainerId,
                method: "GET",
            })
                .then((response) => {
                    setTrainer(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Dashboard contentClass={"trainers"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : trainer ? (
                <TrainerForm brandId={params.brandId} trainer={trainer} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
