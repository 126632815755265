import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IoAccessibilityOutline,
    IoChevronDownOutline,
    IoChevronUpOutline,
    IoEyeOutline,
    IoSearchOutline,
} from "react-icons/io5";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Box, BoxItem, Button, CallTo, ContentHeading, MailTo } from "components/ui";
import { DataEmpty, LoadingSpinner } from "components/common";
import { Link } from "react-router-dom";
import SearchActivityForm from "./SearchActivityForm";
import { DataGrid } from "@mui/x-data-grid";
import { FormatDate, FormatTime } from "utils/Format";

function ActivitiesList({ kids }) {
    const { t } = useTranslation();

    const [activeSearch, setActiveSearch] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [kid, setKid] = useState(kids[0]);
    const [activities, setActivities] = useState(null);

    const columns = [
        {
            field: "name",
            headerName: t("Activity", { ns: "common" }),
            width: 320,
            valueGetter: (params) => `${params.row.activity.name || ""}`,
        },
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 180,
            valueGetter: (params) => `${params.row.brand.name || ""}`,
        },
        {
            field: "when",
            headerName: t("When", { ns: "common" }),
            flex: 1,
            renderCell: (params) => {
                return params.row.activity.period.value === "single" ? (
                    <>
                        <FormatDate date={params.row.activity.date} />{" "}
                        <FormatTime
                            date={params.row.activity.time}
                            formatting={{ hour: "numeric", minute: "numeric" }}
                        />
                    </>
                ) : (
                    <>
                        {params.row.activity.date.map((d) => t(d.name, { ns: "lexicons" })).join(", ")}{" "}
                        <FormatTime
                            date={params.row.activity.time}
                            formatting={{ hour: "numeric", minute: "numeric" }}
                        />
                    </>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                <>
                    <Button className={"btn-action"}>
                        <IoEyeOutline size={"1.25rem"} />
                    </Button>
                </>;
            },
        },
    ];

    useEffect(() => {
        if (kids.length) {
            setLoading(true);

            AxiosInstance({
                url: "kids/" + kid.id + "/activities",
                method: "GET",
            })
                .then((response) => {
                    setActivities(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [kid]);

    return (
        <>
            <ContentHeading tag="h3">
                <IoAccessibilityOutline size={"1.5rem"} />
                {t("Kid activities", { ns: "common" })}
            </ContentHeading>

            <div className="flex">
                {kid && kids.length > 1 && (
                    <div className="content-links">
                        {kids.map((k, index) => (
                            <Button
                                className={k.id === kid.id ? "btn-primary" : "btn-dark-outline btn-opacity"}
                                onClick={() => {
                                    setKid(k);
                                    setActiveSearch(false);
                                }}
                                key={index}
                            >
                                {k.name}
                            </Button>
                        ))}
                    </div>
                )}

                {kid ? (
                    <>
                        <div className="flex-grow"></div>
                        <Button className={"btn-primary"} onClick={() => setActiveSearch(true)}>
                            <IoSearchOutline size={"1.125rem"} />
                            {t("Find new activities", { ns: "common" })}
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </div>

            {isLoading ? (
                <LoadingSpinner />
            ) : kid ? (
                activeSearch ? (
                    <SearchActivityForm kid={kid} setActiveSearch={setActiveSearch} />
                ) : activities ? (
                    <div className="table-box" style={{ height: 600, width: "100%" }}>
                        <DataGrid
                            rows={activities}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            // filterModel={filterModel}
                            pageSizeOptions={[10, 25, 50]}
                            disableColumnMenu={true}
                            width={"100%"}
                            slots={{
                                // toolbar: GridToolbar,
                                columnSortedDescendingIcon: IoChevronUpOutline,
                                columnSortedAscendingIcon: IoChevronDownOutline,
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                        />
                    </div>
                ) : (
                    <DataEmpty title={t("Kid is not enrolled in any activities.", { ns: "common" })} />
                )
            ) : (
                <DataEmpty title={t("You don't have any kids in the system.", { ns: "common" })} />
            )}
        </>
    );
}

export default ActivitiesList;
