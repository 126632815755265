import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import TrainerForm from "./components/TrainerForm";

export const TrainerAddPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        if (params.brandId) {
            AxiosInstance({
                url: "brands/" + params.brandId,
                method: "GET",
            })
                .then((response) => {
                    setPermissions(true);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Dashboard contentClass={"trainers"}>
            {isLoading ? <LoadingSpinner /> : permissions ? <TrainerForm brandId={params.brandId} /> : <AccessDenied />}
        </Dashboard>
    );
};
