import React from "react";
import { PulseLoader } from "react-spinners";

export const LoadingSpinner = ({ children }) => {
    return (
        <div className="spinner-container">
            {children}
            <PulseLoader color={"#94FA46"} speedMultiplier={0.8} />
        </div>
    );
};
