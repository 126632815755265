import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import BrandForm from "./components/BrandForm";

export const BrandEditPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        if (params.brandId) {
            AxiosInstance({
                url: "brands/" + params.brandId,
                method: "GET",
            })
                .then((response) => {
                    setBrand(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        }
    }, []);

    return (
        <Dashboard contentClass={"brands"}>
            {isLoading ? <LoadingSpinner /> : brand ? <BrandForm brand={brand} /> : <AccessDenied />}
        </Dashboard>
    );
};
