import React, { Component } from "react";

import { Dashboard } from "components/common";

class HomePage extends Component {
    constructor(props) {
        super(props);

        // this.state = [];
    }

    componentDidMount() {
        // AxiosInstance({
        //     url: "/users",
        //     method: "GET",
        // }).then((response) => {
        //     console.log(response.data);
        //     console.log(response.data[0].privacyPolicy);
        //     // this.setState(response.data);
        // });
        // UserService.getUserBoard().then(
        //     (response) => {
        //         this.setState({
        //             content: response.data,
        //         });
        //     },
        //     (error) => {
        //         this.setState({
        //             content:
        //                 (error.response && error.response.data && error.response.data.message) ||
        //                 error.message ||
        //                 error.toString(),
        //         });
        //         if (error.response && error.response.status === 401) {
        //             EventBus.dispatch("logout");
        //         }
        //     }
        // );
    }

    render() {
        return (
            <Dashboard>
                <div className="content">{JSON.stringify(this.props.user)}</div>
            </Dashboard>
        );
    }
}

export default HomePage;
