import React from "react";
import { Dialog } from "@headlessui/react";

export const Modal = ({ open = true, handleCloseModal, children, title = null, big = false }) => {
    return (
        <Dialog open={open} onClose={handleCloseModal} className={"modal"}>
            <div className={"modal-backdrop"} aria-hidden="true" />
            <div className={"modal-content"}>
                <Dialog.Panel className={"modal-panel " + (big === true ? "big" : "")}>
                    {title && <Dialog.Title className={"modal-title"}>{title}</Dialog.Title>}
                    <div className={"modal-inner"}>{children}</div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};
