import React, { useEffect } from "react";

import { useLocalStorage } from "hooks/useLocalStorage";
import AxiosInstance from "utils/AxiosInstance";
import AuthService from "../services/AuthService";

export function useLexicons(name) {
    const [lexicons, setLexicons] = useLocalStorage("lexicons", null);

    useEffect(() => {
        const getData = async () => {
            await AxiosInstance({
                url: "lexicons",
                method: "GET",
            }).then((response) => {
                setLexicons(response.data);
            });
        };

        if (AuthService.isToken() && (!lexicons || lexicons === null)) {
            getData();
        }
    }, []);

    return lexicons !== null ? (name ? lexicons[name] : lexicons) : null;
}
