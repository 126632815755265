import { useTranslation } from "react-i18next";

export const FormSchema = () => {
    const { t } = useTranslation();

    return [
        {
            name: "name",
            label: t("Company name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            className: "test",
            fieldClassName: "col-span-full",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-3",
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-4",
        },
        {
            name: "number",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "premises",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-1",
        },
        {
            name: "zipCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "activityDescription",
            label: t("Activity description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "companyDescription",
            label: t("Company description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];
};
