import React, { useEffect, useState } from "react";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";

import AxiosInstance from "utils/AxiosInstance";
import BrandList from "./components/BrandList";

export const BrandsPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [brands, setBrands] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "brands",
            method: "GET",
        })
            .then((response) => {
                setBrands(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"brands"}>
            {isLoading ? <LoadingSpinner /> : brands ? <BrandList brands={brands} /> : <AccessDenied />}
        </Dashboard>
    );
};
