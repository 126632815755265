import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Box, Button, ContentHeading } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { TextArea, TextField, Select2, PriceField, DatePicker, TimePicker, NumberField } from "components/forms";
import { LoadingSpinner } from "components/common";
import { useLexicons } from "hooks/useLexicons";

function ActivityForm({ brandId, activity }) {
    const { t } = useTranslation(["validation"]);
    const lexicons = useLexicons();

    const [isLoading, setLoading] = useState(true);
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [activityCategoriesOptions, setActivityCategoriesOptions] = useState([]);
    const [activityTypesOptions, setActivityTypesOptions] = useState([]);
    const [activityCityOptions, setActivityCityOptions] = useState([]);
    const [activityForWhomOptions, setActivityForWhomOptions] = useState([]);
    const [activityOrganizerOptions, setActivityOrganizerOptions] = useState([]);
    const [activityPeriodOptions, setActivityPeriodOptions] = useState([]);
    const [activityPeriod, setActivityPeriod] = useState(null);

    const defaultValues = {
        name: "",
        category: null,
        type: null,
        city: null,
        period: null,
        date: null,
        time: null,
        price: "",
        length: "",
        groupSize: "",
        forWhom: "",
        territory: "",
        description: "",
    };

    useEffect(() => {
        if (lexicons) {
            setActivityForWhomOptions(lexicons["activity"]["forWhom"]);
            setActivityPeriodOptions(lexicons["activity"]["period"]);
        }

        Promise.all([
            AxiosInstance({
                url: "activity-cities",
                method: "GET",
            }).then((response) => {
                setActivityCityOptions(response.data);
            }),

            AxiosInstance({
                url: "activity-categories",
                method: "GET",
            }).then((response) => {
                setActivityCategoriesOptions(response.data);
            }),

            AxiosInstance({
                url: "week-days",
                method: "GET",
            }).then((response) => {
                setWeekDaysOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });

        if (activity && activity.category) {
            loadTypeByCategory(activity.category.id);
        }

        if (activity && activity.period) {
            setActivityPeriod(activity.period.value);
        }
    }, []);

    function loadTypeByCategory(categoryId) {
        AxiosInstance({
            url: "activity-types/" + categoryId,
            method: "GET",
        }).then((response) => {
            setActivityTypesOptions(response.data);
        });
    }

    const handleChange = async (category) => {
        loadTypeByCategory(category.id);
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "brands/" + brandId + "/activities";
        let method = "POST";

        if (activity) {
            url = "brands/" + brandId + "/activities/" + activity.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!activity && response.status === 201) {
                    resetForm({ values: defaultValues });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, t("Field too short. Minimum number of characters: {{min}}.", { ns: "validation", min: 3 }))
            .max(255, t("Field too long. Maximum number of characters: {{max}}.", { ns: "validation", max: 255 }))
            .required(t("The {{label}} field is required.", { ns: "validation", label: t("Name", { ns: "common" }) })),
        category: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Category", { ns: "common" }) })
        ),
        type: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Type", { ns: "common" }) })
        ),
        city: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("City", { ns: "common" }) })
        ),
        period: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Period", { ns: "common" }) })
        ),
        time: Yup.date().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Hour", { ns: "common" }),
            })
        ),
        price: Yup.string().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Price per activity", { ns: "common" }),
            })
        ),
        length: Yup.string().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Activity length", { ns: "common" }) })
        ),
        groupSize: Yup.number()
            .integer()
            .required(
                t("The {{label}} field is required.", { ns: "validation", label: t("Group size", { ns: "common" }) })
            ),
        territory: Yup.string().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Territorial scope", { ns: "common" }),
            })
        ),
        description: Yup.string().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Description", { ns: "common" }) })
        ),
    });

    return (
        <>
            <ContentHeading tag="h3">
                {activity ? t("Edit activity", { ns: "common" }) : t("Add activities", { ns: "common" })}
            </ContentHeading>

            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Formik
                        initialValues={getInitialValues(defaultValues, activity)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                            return (
                                <Form>
                                    <div className="grid grid-cols-6 gap-6">
                                        <Field
                                            component={TextField}
                                            key={"name"}
                                            name={"name"}
                                            label={t("Name", { ns: "common" })}
                                            required={true}
                                            maxLength={255}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"category"}
                                            name={"category"}
                                            label={t("Category", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={activityCategoriesOptions}
                                            onChange={(val) => {
                                                setFieldValue("category", val);
                                                setFieldValue("type", "");
                                                handleChange(val);
                                            }}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"type"}
                                            name={"type"}
                                            label={t("Type", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={activityTypesOptions}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"city"}
                                            name={"city"}
                                            label={t("City", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={activityCityOptions}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"period"}
                                            name={"period"}
                                            label={t("Period", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={activityPeriodOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            onChange={(option) => {
                                                setFieldValue("period", option);
                                                setActivityPeriod(option.value);
                                            }}
                                        />
                                        {activityPeriod === "SINGLE" ? (
                                            <Field
                                                component={DatePicker}
                                                key={"date"}
                                                name={"date"}
                                                minDate={dayjs()}
                                                label={t("Date of activity", { ns: "common" })}
                                                fieldClassName={"col-span-2"}
                                            />
                                        ) : activityPeriod === "CYCLICAL" ? (
                                            <Field
                                                component={Select2}
                                                key={"date"}
                                                name={"date"}
                                                label={t("Days of the week", { ns: "common" })}
                                                isMulti={true}
                                                fieldClassName={"col-span-2"}
                                                options={weekDaysOptions}
                                                getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <Field
                                            component={TimePicker}
                                            key={"time"}
                                            name={"time"}
                                            label={t("Hour", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                        />
                                        <Field
                                            component={PriceField}
                                            key={"price"}
                                            name={"price"}
                                            label={t("Price per activity", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-start-1 col-span-2"}
                                        />
                                        <Field
                                            component={TextField}
                                            key={"length"}
                                            name={"length"}
                                            label={t("Activity length", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-1"}
                                        />
                                        <Field
                                            component={NumberField}
                                            key={"groupSize"}
                                            name={"groupSize"}
                                            label={t("Group size", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-1"}
                                            decimalScale={0}
                                        />
                                        <Field
                                            component={Select2}
                                            key={"forWhom"}
                                            name={"forWhom"}
                                            label={t("For whom", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={activityForWhomOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                        />
                                        <Field
                                            component={TextField}
                                            key={"territory"}
                                            name={"territory"}
                                            label={t("Territorial scope", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={TextArea}
                                            key={"description"}
                                            name={"description"}
                                            label={t("Description", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary col-start-1"}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                        </Button>
                                        <Link to={"/brand/brands"} className="btn btn-gray" disabled={isSubmitting}>
                                            {t("Cancel", { ns: "button" })}
                                        </Link>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Box>
        </>
    );
}

export default ActivityForm;
