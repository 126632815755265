import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    IoAddOutline,
    IoChevronDownOutline,
    IoChevronUpOutline,
    IoCreateOutline,
    IoEyeOutline,
    IoPeopleOutline,
    IoTrashOutline,
} from "react-icons/io5";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Button, ContentHeading, MuiThemeStyle } from "components/ui";
import TeacherModalView from "./TeacherModalView";
import TeacherModalGroup from "./TeacherModalGroup";
import TeacherModalDelete from "./TeacherModalDelete";

function TeachersList({ teachers, handleLoadData }) {
    const { t } = useTranslation();
    const [modal, setModal] = useState({ show: false, data: null, type: null });

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), flex: 1 },
        {
            field: "phone",
            headerName: t("Phone", { ns: "common" }),
            width: 180,
        },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 360,
            valueGetter: (params) =>
                `${(params.row.groups && params.row.groups.map((group) => group.groupName).join(", ")) || ""}`,
        },
        {
            field: "role",
            headerName: t("Role", { ns: "common" }),
            width: 360,
            valueGetter: (params) =>
                `${(params.row.groups && params.row.groups.map((group) => group.typeName).join(", ")) || ""}`,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/institution/teachers/" + params.id + "/edit"} className={"btn-action"}>
                            <IoCreateOutline size={"1.25rem"} />
                        </Link>
                        <Button className={"btn-action"} onClick={() => openModal(params.id, "view")}>
                            <IoEyeOutline size={"1.25rem"} />
                        </Button>
                        <Button className={"btn-action"} onClick={() => openModal(params.id, "group")}>
                            <IoPeopleOutline size={"1.25rem"} />
                        </Button>
                        <Button className={"btn-action"} onClick={() => openModal(params.id, "delete")}>
                            <IoTrashOutline size={"1.25rem"} />
                        </Button>
                    </>
                );
            },
        },
    ];

    const openModal = (teacherId, type = "view") => {
        setModal({ show: true, data: { teacherId: teacherId }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/institution/teachers/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add teacher", { ns: "button" })}
            >
                <IoPeopleOutline size={"1.5rem"} />
                {t("List of teachers", { ns: "common" })}
            </ContentHeading>

            <div className="table-box max-h-screen" style={{ height: 695, width: "100%" }}>
                <MuiThemeStyle>
                    <DataGrid
                        rows={teachers}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        disableColumnMenu={true}
                        width={"100%"}
                        slots={{
                            // toolbar: GridToolbar,
                            columnSortedDescendingIcon: IoChevronUpOutline,
                            columnSortedAscendingIcon: IoChevronDownOutline,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </MuiThemeStyle>
            </div>

            {modal.show && modal.data && modal.type === "view" && (
                <TeacherModalView data={modal.data} closeModal={handleModalClose} />
            )}
            {modal.show && modal.data && modal.type === "group" && (
                <TeacherModalGroup data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
            {modal.show && modal.data && modal.type === "delete" && (
                <TeacherModalDelete data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
        </>
    );
}

export default TeachersList;
